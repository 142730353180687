import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
  } from '@chakra-ui/react';
  import { useRef } from 'react';
  
  interface DeleteConfirmationDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title?: string;
    itemName?: string;
    isLoading?: boolean;
  }
  
  export const DeleteConfirmationDialog = ({
    isOpen,
    onClose,
    onConfirm,
    title = 'Delete Item',
    itemName,
    isLoading = false,
  }: DeleteConfirmationDialogProps) => {
    const cancelRef = useRef<HTMLButtonElement>(null);
  
    return (
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="#fff9f0">
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color="#4d3d2d">
              {title}
            </AlertDialogHeader>
  
            <AlertDialogBody color="gray.600">
              Are you sure you want to delete {itemName}? This action cannot be undone.
            </AlertDialogBody>
  
            <AlertDialogFooter gap={3}>
              <Button 
                ref={cancelRef} 
                onClick={onClose} 
                variant="ghost"
                isDisabled={isLoading}
              >
                Cancel
              </Button>
              <Button 
                colorScheme="red" 
                onClick={onConfirm}
                isLoading={isLoading}
                loadingText="Deleting"
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  };