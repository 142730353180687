import {
  Box,
  Button,
  IconButton,
  VStack,
  useDisclosure,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { MdError } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocalStorage, STORAGE_KEYS } from './hooks/useLocalStorage';
import { z } from 'zod';
import { MdAdd, MdPrint } from 'react-icons/md';
import { TaskList } from './TaskList';
import { TaskModal } from './TaskModal';
import { FormField } from '@/components/Modal/FormField';
import { Task, TaskType } from '@/lib/sdk/chatbot/types';
import { useEffect, useRef, useState } from 'react';
import { Xid } from 'xid-ts';
import { ChatbotFormData } from '@/lib/sdk/chatbot/types';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';

const formSchema = z.object({
  title: z.string()
    .min(1, 'Title is required')
    .max(55, 'Title must be 55 characters or less'),
  template: z.string().min(1, 'Template is required'),
  tasks: z.array(z.object({
    id: z.string(),
    name: z.string()
      .min(1, 'Task name is required')
      .max(24, 'Task name must be 24 characters or less'),
    description: z.string()
      .max(1024, 'Description must be 1024 characters or less')
      .optional(),
    type: z.string(),
    data: z.string().optional(),
    actions: z.array(z.string()).optional(),
  })),
});


interface ChatbotFormProps {
  onSubmit: (data: ChatbotFormData) => void;
  initialData?: ChatbotFormData;
  isLoading?: boolean;
}

const templateOptions = [
  { value: 'sop', label: 'SOP' },
  { value: 'form', label: 'Form' },
];

export const ChatbotForm = ({
  onSubmit,
  initialData,
  isLoading = false,
}: ChatbotFormProps) => {
  const toast = useToast();
  const { state } = useLocation();
  const [tasks, setTasks] = useState<Task[]>(initialData?.tasks || state?.copiedTasks || []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getItem, setItem, removeItem } = useLocalStorage<ChatbotFormData>(STORAGE_KEYS.FORM_DATA);

  const form = useForm<ChatbotFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: initialData || {
      title: '',
      template: '',
      tasks: [],
    },
  });

  // Load form data from localStorage on mount
  useEffect(() => {
    if (!initialData) {
      const savedData = getItem();
      if (savedData) {
        form.reset(savedData);
        setTasks(savedData.tasks);
      }
    }
  }, []);

  // Save form data to localStorage when it changes
  useEffect(() => {
    const subscription = form.watch((value) => {
      if (!initialData) {
        setItem(value as ChatbotFormData);
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      // Only clean up if not in edit mode
      if (!initialData) {
        removeItem();
      }
    };
  }, [removeItem, initialData]);

  const handleAddTask = (task: Task) => {
    const newTask = {
      ...task,
      id: new Xid().toString(),
      type: task.type || TaskType.REGULAR,
    };
    
    const updatedTasks = [...tasks, newTask];
    setTasks(updatedTasks);
    form.setValue('tasks', updatedTasks);
    
    toast({
      title: 'Task added',
      status: 'success',
      duration: 2000,
    });
  };

  const handleTaskEdit = (updatedTask: Task) => {
    const updatedTasks = tasks.map(task =>
      task.id === updatedTask.id ? updatedTask : task
    );
    setTasks(updatedTasks);
    form.setValue('tasks', updatedTasks);
  };

  const handleTaskDelete = (taskId: string) => {
    const updatedTasks = tasks.filter(task => task.id !== taskId);
    setTasks(updatedTasks);
    form.setValue('tasks', updatedTasks);
  };

  const handleTasksChange = (updatedTasks: Task[]) => {
    setTasks(updatedTasks);
    form.setValue('tasks', updatedTasks);
  };

  const handleFormSubmit = (data: ChatbotFormData) => {
    // Ensure tasks are included in the form data
    const formData = {
      ...data,
      tasks,
    };
    onSubmit(formData);
  };

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  const onPrint = () => {
    if (reactToPrintFn) {
      reactToPrintFn();
    }
  };

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const { isOpen: isErrorDialogOpen, onOpen: onErrorDialogOpen, onClose: onErrorDialogClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  // Function to format error messages
  const formatErrorMessage = (error: any, path: string): string => {
    if (typeof error === 'string') return error;
    if (error?.message) return error.message;
    
    // Handle nested task errors
    if (path.startsWith('tasks')) {
      const [_, index, field] = path.split('.');
      return `Task ${Number(index) + 1}: ${error?.message || 'Invalid field'}`;
    }
    
    return 'Invalid field';
  };

  // Function to process form errors
  const processFormErrors = (errors: any) => {
    const errorMessages: string[] = [];

    Object.entries(errors).forEach(([key, value]: [string, any]) => {
      if (key === 'tasks' && Array.isArray(value)) {
        // Handle task array errors
        value.forEach((taskError: any, index: number) => {
          if (taskError) {
            Object.entries(taskError).forEach(([field, error]: [string, any]) => {
              errorMessages.push(formatErrorMessage(error, `tasks.${index}.${field}`));
            });
          }
        });
      } else {
        // Handle top-level form errors
        errorMessages.push(formatErrorMessage(value, key));
      }
    });

    return errorMessages;
  };

  const onInvalid = (errors: any) => {
    const errorMessages = processFormErrors(errors);
    setValidationErrors(errorMessages);
    onErrorDialogOpen();
  };

  return (
    <Box
      as="form"
      onSubmit={form.handleSubmit(handleFormSubmit,onInvalid )}
      bg="#faeed9"
      p={6}
      rounded="md"
      shadow="md"
    >
      <VStack spacing={6} align="stretch">
        <FormField
          label="Title"
          name="title"
          register={form.register}
          error={form.formState.errors.title}
          required
          placeholder="Enter chatbot title"
          maxLength={55}
        />

        <FormField
          label="Template Type"
          name="template"
          type="select"
          options={templateOptions}
          register={form.register}
          error={form.formState.errors.template}
          required
        />

        <Box>
          <Button
            leftIcon={<MdAdd />}
            onClick={onOpen}
            mb={4}
            colorScheme="whatsapp"
          >
            Add Task
          </Button>
          <IconButton
            icon={<MdPrint />}
            onClick={onPrint}
            color="whatsapp.500"
            aria-label='Export'
            variant='ghost'
            ml={2}
            mb={4}
            size="md"
          />

          <TaskList
            tasks={tasks}
            onTasksChange={handleTasksChange}
            onTaskEdit={handleTaskEdit}
            onTaskDelete={handleTaskDelete}
            innerRef={contentRef} 
          />
        </Box>

        <Button
          type="submit"
          colorScheme="whatsapp"
          isLoading={isLoading}
          loadingText={initialData ? 'Updating...' : 'Creating...'}
        >
          {initialData ? 'Update' : 'Create'} Chatbot
        </Button>
      </VStack>
      {/* Error Dialog */}
      <AlertDialog
        isOpen={isErrorDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onErrorDialogClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="#fff9f0">
            <AlertDialogHeader 
              fontSize="lg" 
              fontWeight="bold" 
              color="#4d3d2d"
            >
              Form Validation Errors
            </AlertDialogHeader>

            <AlertDialogBody>
              <List spacing={3}>
                {validationErrors.map((error, index) => (
                  <ListItem 
                    key={index}
                    display="flex"
                    alignItems="center"
                    color="red.500"
                  >
                    <ListIcon as={MdError} color="red.500" />
                    {error}
                  </ListItem>
                ))}
              </List>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onErrorDialogClose}
                colorScheme="whatsapp"
              >
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <TaskModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleAddTask}
      />
    </Box>
  );
};