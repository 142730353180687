import { useEffect, useCallback } from 'react';

export function useTouchScroll() {
  // Store original body style
  let originalStyle: string;

  const preventScroll = useCallback((e: TouchEvent) => {
    e.preventDefault();
  }, []);

  const disableScroll = useCallback(() => {
    // Store original style for restoration later
    originalStyle = window.getComputedStyle(document.body).overflow;
    
    // Prevent scrolling on the body
    document.body.style.overflow = 'hidden';
    
    // Prevent touch move events
    document.addEventListener('touchmove', preventScroll, { passive: false });
  }, [preventScroll]);

  const enableScroll = useCallback(() => {
    // Restore original body style
    document.body.style.overflow = originalStyle;
    
    // Remove touch move event listener
    document.removeEventListener('touchmove', preventScroll);
  }, [preventScroll]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      enableScroll();
    };
  }, [enableScroll]);

  return {
    disableScroll,
    enableScroll,
  };
}