import { useRef, useCallback } from 'react';
import { DragMoveEvent } from '@dnd-kit/core';

export function useAutoScroll(scrollSpeed = 10) {
  const scrollingArea = useRef<HTMLDivElement>(null);
  const scrollingInterval = useRef<number>();

  const handleDragMove = useCallback((event: DragMoveEvent) => {
    const container = scrollingArea.current;
    if (!container) return;

    const { active, delta } = event;
    const rect = container.getBoundingClientRect();
    const scrollThreshold = 50; // pixels from edge to start scrolling

    // Clear any existing interval
    if (scrollingInterval.current) {
      window.clearInterval(scrollingInterval.current);
    }

    // Calculate scroll direction and speed
    const scrollUp = event.clientY - rect.top < scrollThreshold;
    const scrollDown = rect.bottom - event.clientY < scrollThreshold;

    if (scrollUp || scrollDown) {
      scrollingInterval.current = window.setInterval(() => {
        if (scrollUp) {
          container.scrollTop -= scrollSpeed;
        } else if (scrollDown) {
          container.scrollTop += scrollSpeed;
        }
      }, 16); // ~60fps
    }
  }, [scrollSpeed]);

  // Clean up interval on unmount
  const cleanup = useCallback(() => {
    if (scrollingInterval.current) {
      window.clearInterval(scrollingInterval.current);
    }
  }, []);

  return {
    scrollingArea,
    handleDragMove,
    cleanup,
  };
};