import { Box, useBreakpointValue, useToast } from '@chakra-ui/react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  DragOverlay,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { TaskTable } from './TaskTable';
import { useTaskListState } from './hooks/useTaskListState';
import { Task } from '@/lib/sdk/chatbot/types';
import { useAutoScroll } from './hooks/useAutoScroll';
import { useTouchScroll } from './hooks/useTouchScroll';

interface TaskListProps {
  tasks: Task[];
  onTasksChange: (tasks: Task[]) => void;
  onTaskEdit: (task: Task) => void;
  onTaskDelete: (taskId: string) => void;
  innerRef: any;
}

export const TaskList = ({ 
  tasks, 
  onTasksChange, 
  onTaskEdit, 
  onTaskDelete,
  innerRef,
}: TaskListProps) => {
  const toast = useToast();
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { disableScroll, enableScroll } = useTouchScroll();

  const {
    activeId, // Add this
    handleDragStart,
    handleDragEnd,
    handleReorder,
  } = useTaskListState(tasks, onTasksChange);

  // Configure sensors for both mouse/keyboard and touch interactions
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Set up auto-scrolling during drag
  const { scrollingArea, handleDragMove } = useAutoScroll();

  const handleDragStartEvent = (event: DragStartEvent) => {
    const { active } = event;

    // Disable scrolling on drag start for mobile
    if (!isDesktop) {
      disableScroll();
    }

    // Provide haptic feedback on mobile devices
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }

    handleDragStart(active.id as string);
  };

  const handleDragEndEvent = (event: DragEndEvent) => {
    const { active, over } = event;
    
    // Enable scrolling on drag end for mobile
    if (!isDesktop) {
      enableScroll();
    }
    
    if (over && active.id !== over.id) {
      const oldIndex = tasks.findIndex(task => task.id === active.id);
      const newIndex = tasks.findIndex(task => task.id === over.id);
      
      handleReorder(oldIndex, newIndex);

      // Show success toast
      toast({
        title: "Task reordered",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }

    handleDragEnd();
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStartEvent}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEndEvent}
    >
      <Box 
        ref={scrollingArea}
        width="100%" 
        overflowX={isDesktop ? "auto" : "visible"}
        overflowY="auto"
        maxHeight="70vh"
        position="relative"
      >
        <SortableContext
          items={tasks.map(task => task.id)}
          strategy={verticalListSortingStrategy}
        >
          <TaskTable
            tasks={tasks}
            onTaskEdit={onTaskEdit}
            onTaskDelete={onTaskDelete}
            innerRef={innerRef}
          />
        </SortableContext>

        {/* Drag Overlay for visual feedback */}
        <DragOverlay>
          {tasks.find(task => task.id === activeId) && (
            <Box
              bg="white"
              p={4}
              rounded="md"
              shadow="lg"
              opacity={0.8}
              transform="scale(1.05)"
              transition="transform 0.2s"
            >
              {tasks.find(task => task.id === activeId)?.name}
            </Box>
          )}
        </DragOverlay>
      </Box>
    </DndContext>
  );
};