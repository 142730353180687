import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  useToast,
  Box,
  CheckboxGroup,
  SimpleGrid,
  Checkbox,
  Divider,
  FormLabel,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FormField } from '@/components/Modal/FormField';
import { TaskField } from './TaskField';
import { Task, TaskType, ActionMap, TaskFormData } from '@/lib/sdk/chatbot/types';
import { useEffect } from 'react';

// Schema with dynamic field validation
const taskSchema = z.object({
  name: z.string()
    .min(1, 'Task name is required')
    .max(24, 'Task name must be 24 characters or less'),
  description: z.string()
    .max(72, 'Description must be 72 characters or less')
    .optional(),
  type: z.string().min(1, 'Task type is required'),
  data: z.string().optional(),
  actions: z.array(z.string()).default([]),
});

interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Task) => void;
  initialData?: Task;
  isEditing?: boolean;
}

export const TaskModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  isEditing = false,
}: TaskModalProps) => {
  const toast = useToast();
  const form = useForm<TaskFormData>({
    resolver: zodResolver(taskSchema),
    defaultValues: initialData || {
      name: '',
      description: '',
      type: TaskType.REGULAR,
      data: '',
      actions: [],
    },
  });

  // Reset form when modal opens with initial data
  useEffect(() => {
    if (isOpen && initialData) {
      form.reset(initialData);
    }
  }, [isOpen, initialData, form]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const data = await form.handleSubmit((formData) => {
        // Ensure all required fields are present
        const task: Task = {
          ...formData,
          id: initialData?.id || '', // ID will be generated by parent if not editing
          type: formData.type || TaskType.REGULAR,
          actions: formData.actions || [],
        };

        onSubmit(task);
        form.reset();
        onClose();
      })(e);

      return data;
    } catch (error: any) {
      toast({
        title: 'Validation Error',
        description: error.message || 'Please check all required fields',
        status: 'error',
        duration: 3000,
      });
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent bg="#fff9f0">
        <form onSubmit={handleSubmit}>
          <ModalHeader 
            color="#4d3d2d"
            borderBottom="1px solid"
            borderColor="gray.200"
            pb={4}
          >
            {isEditing ? 'Edit Task' : 'Add Task'}
          </ModalHeader>
          <ModalCloseButton color="#4d3d2d" />
          
          <ModalBody>
            <VStack spacing={6} align="stretch" py={4}>
              {/* Basic Fields Section */}
              <Box>
                <FormField
                  label="Task Name"
                  name="name"
                  register={form.register}
                  error={form.formState.errors.name}
                  required
                  placeholder="Enter task name"
                  maxLength={24}
                />

                <Box mt={4}>
                  <FormField
                    label="Description"
                    name="description"
                    register={form.register}
                    error={form.formState.errors.description}
                    type="textarea"
                    placeholder="Enter task description"
                    maxLength={72}
                  />
                </Box>
              </Box>

              <Divider />

              {/* Task Configuration Section */}
              <Box>
                <FormLabel 
                  color="#4d3d2d"
                  fontSize="md"
                  fontWeight="medium"
                  mb={4}
                >
                  Task Configuration
                </FormLabel>
                <TaskField
                  register={form.register}
                  control={form.control}
                  setValue={form.setValue}
                  errors={form.formState.errors}
                />
              </Box>

              <Divider />

              {/* Actions Section */}
              <Box>
                <Controller
                  name="actions"
                  control={form.control}
                  defaultValue={[]}
                  render={({ field: { onChange, value } }) => (
                    <VStack align="stretch" spacing={3}>
                      <FormLabel
                        color="#4d3d2d"
                        fontSize="md"
                        fontWeight="medium"
                      >
                        Task Actions
                      </FormLabel>
                      <Box 
                        bg="white" 
                        p={4} 
                        rounded="md" 
                        borderWidth="1px"
                        borderColor="gray.200"
                      >
                        <CheckboxGroup
                          colorScheme="whatsapp"
                          value={value}
                          onChange={onChange}
                        >
                          <SimpleGrid 
                            columns={{ base: 1, md: 2 }} 
                            spacing={4}
                          >
                            {Object.entries(ActionMap).map(([key, value]) => (
                              <Checkbox
                                key={key}
                                value={value}
                                borderColor="#4d3d2d"
                                sx={{
                                  '[data-checked]': {
                                    borderColor: '#25D366',
                                    backgroundColor: '#25D366',
                                  }
                                }}
                              >
                                {key}
                              </Checkbox>
                            ))}
                          </SimpleGrid>
                        </CheckboxGroup>
                      </Box>
                    </VStack>
                  )}
                />
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter 
            gap={3}
            borderTop="1px solid"
            borderColor="gray.200"
            pt={4}
          >
            <Button
              variant="ghost"
              onClick={onClose}
              isDisabled={form.formState.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="whatsapp"
              isLoading={form.formState.isSubmitting}
              loadingText="Saving"
            >
              {isEditing ? 'Update' : 'Add'} Task
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};