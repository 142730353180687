import { useState, useCallback, useRef } from 'react';
import { Task } from '@/lib/sdk/chatbot/types';

export function useTaskListState(
  tasks: Task[],
  onTasksChange: (tasks: Task[]) => void
) {
  const [activeId, setActiveId] = useState<string | null>(null);
  const dragDelayTimerRef = useRef<number>();
  const dragStartPositionRef = useRef<{ x: number; y: number } | null>(null);

  const handleDragStart = useCallback((
    id: string, 
    x: number, 
    y: number
  ) => {
    dragStartPositionRef.current = { x, y };
    
    // Add delay before starting drag
    dragDelayTimerRef.current = window.setTimeout(() => {
      const currentPosition = dragStartPositionRef.current;
      if (currentPosition) {
        // Check if moved more than threshold
        const dx = Math.abs(x - currentPosition.x);
        const dy = Math.abs(y - currentPosition.y);
        if (dx < 5 && dy < 5) {
          setActiveId(id);
          // Provide haptic feedback
          if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
          }
        }
      }
    }, 200); // 200ms delay
  }, []);

  const handleDragEnd = useCallback(() => {
    if (dragDelayTimerRef.current) {
      window.clearTimeout(dragDelayTimerRef.current);
    }
    dragStartPositionRef.current = null;
    setActiveId(null);
  }, []);

  const handleReorder = useCallback((oldIndex: number, newIndex: number) => {
    const updatedTasks = [...tasks];
    const [movedTask] = updatedTasks.splice(oldIndex, 1);
    updatedTasks.splice(newIndex, 0, movedTask);
    onTasksChange(updatedTasks);
  }, [tasks, onTasksChange]);

  return {
    activeId,
    handleDragStart,
    handleDragEnd,
    handleReorder,
  };
}