import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    VStack,
    Box,
    Text,
    List,
    ListItem,
    ListIcon,
    Divider,
    Button,
  } from '@chakra-ui/react';
  import { MdAdd, MdEdit, MdDelete, MdCheck } from 'react-icons/md';
  import { TaskChanges } from '@/lib/sdk/chatbot/types';
  
  interface UpdateDialogProps {
    isOpen: boolean;
    onClose: () => void;
    changes: TaskChanges[];
  }
  
  export const UpdateDialog = ({ isOpen, onClose, changes }: UpdateDialogProps) => {
    // Group changes by type
    const addedTasks = changes.filter(task => task.added);
    const editedTasks = changes.filter(task => task.edited);
    const deletedTasks = changes.filter(task => task.deleted);
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent bg="#fff9f0">
          <ModalHeader color="#4d3d2d">Task Changes Summary</ModalHeader>
          <ModalCloseButton color="#4d3d2d" />
          
          <ModalBody>
            <VStack spacing={4} align="stretch">
              {/* Added Tasks */}
              {addedTasks.length > 0 && (
                <Box>
                  <Text 
                    fontSize="md" 
                    fontWeight="semibold" 
                    color="#4d3d2d" 
                    mb={2}
                  >
                    {addedTasks.length === changes.length 
                      ? `Added ${addedTasks.length} new task${addedTasks.length > 1 ? 's' : ''}`
                      : 'Added Tasks'
                    }
                  </Text>
                  <List spacing={2}>
                    {addedTasks.map(task => (
                      <ListItem 
                        key={task.id}
                        display="flex"
                        alignItems="center"
                        color="gray.600"
                      >
                        <ListIcon as={MdAdd} color="green.500" />
                        {task.name}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
  
              {/* Divider between sections if multiple types exist */}
              {addedTasks.length > 0 && (editedTasks.length > 0 || deletedTasks.length > 0) && (
                <Divider borderColor="#4d3d2d" opacity={0.2} />
              )}
  
              {/* Edited Tasks */}
              {editedTasks.length > 0 && (
                <Box>
                  <Text 
                    fontSize="md" 
                    fontWeight="semibold" 
                    color="#4d3d2d" 
                    mb={2}
                  >
                    Modified Tasks
                  </Text>
                  <List spacing={2}>
                    {editedTasks.map(task => (
                      <ListItem 
                        key={task.id}
                        display="flex"
                        alignItems="center"
                        color="gray.600"
                      >
                        <ListIcon as={MdEdit} color="blue.500" />
                        {task.name}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
  
              {/* Divider before deleted tasks if they exist */}
              {editedTasks.length > 0 && deletedTasks.length > 0 && (
                <Divider borderColor="#4d3d2d" opacity={0.2} />
              )}
  
              {/* Deleted Tasks */}
              {deletedTasks.length > 0 && (
                <Box>
                  <Text 
                    fontSize="md" 
                    fontWeight="semibold" 
                    color="#4d3d2d" 
                    mb={2}
                  >
                    Deleted Tasks
                  </Text>
                  <List spacing={2}>
                    {deletedTasks.map(task => (
                      <ListItem 
                        key={task.id}
                        display="flex"
                        alignItems="center"
                        color="gray.600"
                      >
                        <ListIcon as={MdDelete} color="red.500" />
                        {task.name}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
  
              {/* No changes message */}
              {changes.length === 0 && (
                <Text color="gray.600" textAlign="center">
                  No changes were made to the tasks.
                </Text>
              )}
            </VStack>
          </ModalBody>
  
          <ModalFooter>
            <Button
              colorScheme="whatsapp"
              leftIcon={<MdCheck />}
              onClick={onClose}
              size="md"
              width={{ base: "full", sm: "auto" }}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };