import { Box, Heading, VStack, useToast } from '@chakra-ui/react';
import { ChatbotForm } from '@/components/Chatbot/v3/ChatbotForm';
import { Breadcrumbs } from '@/components/Breadcrumbs/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateChatbot } from '@/lib/sdk/chatbot/queries';
import type { ChatbotFormData } from '@/components/Chatbot/v2/types';
import { useState } from 'react';

export const CreateChatbotV2 = () => {
  const toast = useToast();
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const createChatbotMutation = useCreateChatbot();

  const breadcrumbItems = [
    { label: 'Dashboard', href: `/dashboard/${orgId}` },
    { label: 'Create Chatbot V2' },
  ];

  const handleSubmit = async (data: ChatbotFormData) => {
    try {
      setIsLoading(true);
      await createChatbotMutation.mutateAsync({
        mode: 'ui',
        data: {
          title: data.title,
          template: data.template,
          tasks: data.tasks,
        }
      });
      toast({
        title: 'Chatbot created successfully',
        status: 'success',
        duration: 3000,
      });
      navigate(`/dashboard/${orgId}`);
    } catch (error: any) {
      toast({
        title: 'Error creating chatbot',
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <VStack align="stretch" spacing={6}>
        <Breadcrumbs items={breadcrumbItems} />
        
        <Heading size={{ base: "md", md: "lg" }}>
          Create New Chatbot
        </Heading>

        <ChatbotForm onSubmit={handleSubmit} isLoading={isLoading}/>
      </VStack>
    </Box>
  );
};

export default CreateChatbotV2;
