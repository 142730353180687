import { useState, useCallback, useEffect } from 'react';

export const STORAGE_KEYS = {
  TASKS: 'chatbot_tasks',
  FORM_DATA: 'chatbot_form_data',
} as const;

export type StorageKey = typeof STORAGE_KEYS[keyof typeof STORAGE_KEYS];

export function useLocalStorage<T>(key: StorageKey) {
  const [storedValue, setStoredValue] = useState<T | null>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(`Error reading ${key} from localStorage:`, error);
      return null;
    }
  });

  const setItem = useCallback((value: T) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      setStoredValue(value);
    } catch (error) {
      console.error(`Error writing ${key} to localStorage:`, error);
    }
  }, [key]);

  const getItem = useCallback(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(`Error reading ${key} from localStorage:`, error);
      return null;
    }
  }, [key]);

  const removeItem = useCallback(() => {
    try {
      window.localStorage.removeItem(key);
      setStoredValue(null);
    } catch (error) {
      console.error(`Error removing ${key} from localStorage:`, error);
    }
  }, [key]);

  // Cleanup on unmount or route change
  useEffect(() => {
    return () => {
      removeItem();
    };
  }, [removeItem]);

  return {
    storedValue,
    setItem,
    getItem,
    removeItem,
  };
}