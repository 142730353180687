import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { ProtectedRoute } from '@/components/Auth/ProtectedRoute';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import CreateChatbot from './pages/CreateChatbot';
import CreateChatbotV2 from './pages/CreateChatbotV2';
import EditChatbot from './pages/EditChatbot';
const DashboardLayout = lazy(() => import(/* webpackChunkName: "dashboardLayout" */'@/components/DashboardLayout'));
const LandingPage = lazy(() => import(/* webpackChunkName: "homeV1" */'@/pages/LandingPage'));
const LandingPageV2 = lazy(() => import(/* webpackChunkName: "homeV2" */'@/pages/LandingPageAlt1'));
const AuthPage = lazy(() => import(/* webpackChunkName: "login" */'@/pages/AuthPage'));
const TermsPage = lazy(() => import(/* webpackChunkName: "terms" */'@/pages/TermsPage'));
const AboutPage = lazy(() => import(/* webpackChunkName: "about" */'@/pages/AboutPage'));
const PrivacyPage = lazy(() => import(/* webpackChunkName: "privacy" */'@/pages/PrivacyPage'));
const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */'@/pages/Dashboard'));
const UsersPage = lazy(() => import(/* webpackChunkName: "users" */'@/pages/UsersPage'));
const CalendarPage = lazy(() => import(/* webpackChunkName: "calendar" */'@/pages/CalendarPage'));
const OrganizationsPage = lazy(() => import(/* webpackChunkName: "org" */'@/pages/OrganizationsPage'));
const CalendarUserMapping = lazy(() => import(/* webpackChunkName: "calendarUsers" */'@/pages/CalendarUserMapping'));
const ReportsPage = lazy(() => import(/* webpackChunkName: "reports" */'@/pages/Reports/ReportsPage'));
const SharedReportPage = lazy(() => import(/* webpackChunkName: "sharedReports" */'@/pages/Reports/SharedReportPage'));
const ReportsDashboard = lazy(() => import(/* webpackChunkName: "chatbotReports" */'@/pages/Reports/ReportsDashboard'));
const OrgDashboards = lazy(() => import(/* webpackChunkName: "orgReports" */'@/pages/Reports/OrgReportsDashboard'));

const adminPath = import.meta.env.VITE_ADMIN_URL_PATH
const LazyWrapper = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<LoadingSpinner />}>
    {children}
  </Suspense>
);

export const createRoutes = () => [
    {
      path: '/',
      element: <LazyWrapper><LandingPageV2 /></LazyWrapper>,
    },
    {
      path: '/v2',
      element: <LazyWrapper><LandingPage /></LazyWrapper>,
    },
    {
      path: '/auth/:mode',
      element: <LazyWrapper><AuthPage /></LazyWrapper>,
    },
    {
      path: `${adminPath}/orgs`,
      element: <OrganizationsPage/>
    },
    {
      path: `/reports/:shareId`,
      element: <LazyWrapper><SharedReportPage /></LazyWrapper>,
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <LazyWrapper>
            <DashboardLayout />
          </LazyWrapper>
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'dashboard/:orgId',
          element: <LazyWrapper><Dashboard /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/team',
          element: <LazyWrapper><UsersPage /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/reminders',
          element: <LazyWrapper><CalendarPage /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/dashboards',
          element: <LazyWrapper><OrgDashboards /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/create-chatbot',
          element: <LazyWrapper><CreateChatbot /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/edit-chatbot/:chatbotId',
          element: <LazyWrapper><EditChatbot /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/chatbots/:chatbotId/reports',
          element: <LazyWrapper><ReportsPage /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/chatbots/:chatbotId/dashboard',
          element: <LazyWrapper><ReportsDashboard /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/reminders/:eventId/users',
          element: <LazyWrapper><CalendarUserMapping /></LazyWrapper>,
        },
        {
          path: 'dashboard/:orgId/create-chatbot-v2',
          element: <LazyWrapper><CreateChatbotV2 /></LazyWrapper>,
        },
        {
          path: '*',
          element: <Navigate to="/" replace />,
        },
      ],
    },
    {
      path: "/terms",
      element: <TermsPage/>
    },
    {
      path: "/about",
      element: <AboutPage/>
    },
    {
      path: "/privacy",
      element: <PrivacyPage/>
    },
];