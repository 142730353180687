import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  useToast,
  HStack,
  Badge,
  Tag,
  TagLabel,
  TagLeftIcon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  useMediaQuery,
  Text,
  Portal,
  VStack,
  IconButton,
} from '@chakra-ui/react';
import { MdEdit, MdDelete, MdDragIndicator, MdCheck, MdClose } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Task, TaskType, ActionMap } from '@/lib/sdk/chatbot/types';
import { DeleteConfirmationDialog } from '@/components/DeleteConfirmationDialog';
import { TaskModal } from './TaskModal';
import { useLocalStorage, STORAGE_KEYS } from './hooks/useLocalStorage';
import { useEffect, useState, useRef } from 'react';

// Theme colors
const COLORS = {
  border: '#8B7355',
  borderLight: '#A0522D',
  headerBg: '#DEB887',
  hoverBg: '#F5DEB3',
  text: '#4d3d2d',
  statusColors: {
    pending: '#FBD38D',
    inProgress: '#63B3ED',
    completed: '#68D391',
  },
};

interface FloatingActionPopoverProps {
  task: Task;
  onEdit: () => void;
  onDelete: () => void;
  isOpen: boolean;
  onClose: () => void;
}

const FloatingActionPopover = ({ onEdit, onDelete, onClose }: FloatingActionPopoverProps) => {
  return (
    <Portal>
      <PopoverContent
        bg="#fff9f0"
        border="none"
        shadow="lg"
        _focus={{ outline: 'none' }}
        zIndex={1400}
        width="auto"
      >
        <PopoverBody p={2}>
          <HStack spacing={2}>
            <IconButton
              icon={<MdEdit size={20} />}
              aria-label="Edit task"
              variant="ghost"
              size="sm"
              onClick={() => {
                onEdit();
                onClose();
              }}
              w="full"
            />
            <IconButton
              icon={<MdDelete size={20} />}
              aria-label="Delete task"
              variant="ghost"
              colorScheme="red"
              size="sm"
              onClick={() => {
                onDelete();
                onClose();
              }}
              w="full"
            />
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Portal>
  );
};


interface TaskTableProps {
  tasks: Task[];
  onTaskEdit: (task: Task) => void;
  onTaskDelete: (taskId: string) => void;
  innerRef: any;
}

const SortableRow = ({
  task,
  onEdit,
  onDelete,
}: {
  task: Task;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: task.id });
  const [isTouch] = useMediaQuery('(hover: none)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [longPressTimer, setLongPressTimer] = useState<NodeJS.Timeout>();

  const handleTouchStart = () => {
    const timer = setTimeout(() => {
      onOpen();
    }, 500);
    setLongPressTimer(timer);
  };

  const handleTouchEnd = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer);
    }
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    backgroundColor: isDragging ? COLORS.hoverBg : undefined,
    position: 'relative' as const,
    zIndex: isDragging ? 1 : 'auto',
  };

  const getStatusColor = (type: string) => {
    switch (type) {
      case TaskType.REGULAR:
        return COLORS.statusColors.pending;
      case TaskType.INTRO:
        return COLORS.statusColors.inProgress;
      case TaskType.END:
        return COLORS.statusColors.completed;
      default:
        return COLORS.statusColors.pending;
    }
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      placement="auto"
      closeOnBlur={true}
      isLazy
    >
    <PopoverTrigger>
      <Tr
        ref={setNodeRef}
        style={style}
        _hover={{ bg: COLORS.hoverBg }}
        role="group"
        borderBottom="1px solid"
        borderColor={COLORS.borderLight}
        onDoubleClick={!isTouch ? onOpen : undefined}
        onTouchStart={isTouch ? handleTouchStart : undefined}
        onTouchEnd={isTouch ? handleTouchEnd : undefined}
        onTouchMove={isTouch ? handleTouchEnd : undefined}
        cursor="pointer"
        position="relative"
        bg={isOpen ? COLORS.hoverBg : undefined}
      >
        {/* Drag Handle */}
        <Td width="44px" borderRight="1px solid" borderColor={COLORS.borderLight}>
          <Box
            cursor="grab"
            {...attributes}
            {...listeners}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="44px"
          >
            <MdDragIndicator size={20} color={COLORS.text} />
          </Box>
        </Td>

        {/* Task Name & Description */}
        <Td borderRight="1px solid" borderColor={COLORS.borderLight} width="250px">
          <VStack align="flex-start" spacing={1}>
            <Text fontWeight="medium" color={COLORS.text}>
              {task.name}
            </Text>
            {task.description && (
              <Text color="gray.600" fontSize="sm">
                {task.description}
              </Text>
            )}
          </VStack>
        </Td>

        {/* Task Type & Data */}
        <Td borderRight="1px solid" borderColor={COLORS.borderLight}>
          <VStack align="flex-start" spacing={2}>
            <Badge
              colorScheme="whatsapp"
              bg={getStatusColor(task.type)}
              color={COLORS.text}
              px={2}
              py={1}
              borderRadius="full"
            >
              {task.type}
            </Badge>
            {task.data && (
              <Text color="gray.600" fontSize="sm">
                {task.data}
              </Text>
            )}
          </VStack>
        </Td>

        {/* Task Actions */}
        <Td borderRight="1px solid" borderColor={COLORS.borderLight}>
          <HStack spacing={2} wrap="wrap">
            {task.actions?.map((action) => (
              <Tag
                key={action}
                size="md"
                variant="subtle"
                colorScheme="whatsapp"
                borderRadius="full"
              >
                <TagLeftIcon 
                  as={action === ActionMap.SKIPPABLE ? MdClose : MdCheck} 
                  boxSize="12px" 
                />
                <TagLabel fontSize="xs">
                  {Object.keys(ActionMap).find(key => ActionMap[key as keyof typeof ActionMap] === action)}
                </TagLabel>
              </Tag>
            ))}
          </HStack>
        </Td>
      </Tr>
      </PopoverTrigger>
      <FloatingActionPopover
        task={task}
        onEdit={onEdit}
        onDelete={onDelete}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Popover>
  );
};

export const TaskTable = ({
  tasks,
  onTaskEdit,
  onTaskDelete,
  innerRef,
}: TaskTableProps) => {
  const toast = useToast();
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { getItem, setItem, removeItem } = useLocalStorage<Task[]>(STORAGE_KEYS.TASKS);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isTouch] = useMediaQuery('(hover: none)');


  // Touch scroll handling
  const handleTouchStart = (e: React.TouchEvent) => {
    if (!scrollContainerRef.current) return;
    setIsDragging(true);
    setStartX(e.touches[0].pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging || !scrollContainerRef.current) return;
    e.preventDefault();
    const x = e.touches[0].pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  // Load tasks from localStorage on mount
  useEffect(() => {
    const savedTasks = getItem();
    if (savedTasks) {
      onTaskEdit(savedTasks);
    }
  }, []);

  // Save tasks to localStorage when they change
  useEffect(() => {
    setItem(tasks);
  }, [tasks]);

  const handleEdit = (task: Task) => {
    setSelectedTask(task);
    onEditOpen();
  };

  const handleDelete = (task: Task) => {
    setSelectedTask(task);
    onDeleteOpen();
  };

  const handleDeleteConfirm = () => {
    if (selectedTask) {
      onTaskDelete(selectedTask.id);
      toast({
        title: 'Task deleted',
        status: 'success',
        duration: 2000,
      });
    }
    onDeleteClose();
  };

  const handleTaskUpdate = (updatedTask: Task) => {
    onTaskEdit(updatedTask);
    toast({
      title: 'Task updated',
      status: 'success',
      duration: 2000,
    });
    onEditClose();
  };

  useEffect(() => {
    return () => {
      removeItem();
    };
  }, [removeItem]);

  return (
    <>
      <Box
        ref={scrollContainerRef}
        overflowX="auto"
        overflowY="auto"
        maxHeight="70vh"
        borderWidth="1px"
        borderRadius="lg"
        borderColor={COLORS.border}
        shadow="sm"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        position="relative"
        css={{
          WebkitOverflowScrolling: 'touch',
          scrollBehavior: 'smooth',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '@media (hover: none)': {
            cursor: 'grab',
          }
        }}
      >
        {/* User guidance */}
        <Box
          position="absolute"
          top={2}
          right={2}
          zIndex={2}
          bg="whiteAlpha.900"
          p={2}
          rounded="md"
          shadow="sm"
        >
          <Text fontSize="sm" color="gray.600">
            {isTouch 
              ? "Long press any row to edit or delete"
              : "Double-click any row to edit or delete"
            }
          </Text>
        </Box>
        <Table variant="simple" style={{ minWidth: '800px' }} ref={innerRef}>
          <Thead style={{ position: 'sticky', top: 0, zIndex: 1, background: COLORS.headerBg }}>
            <Tr>
              <Th width="44px" p={0} borderRight="1px solid" borderColor={COLORS.border}></Th>
              <Th borderRight="1px solid" borderColor={COLORS.border} width="250px">
                <Text color={COLORS.text} fontWeight="semibold">Task Details</Text>
              </Th>
              <Th borderRight="1px solid" borderColor={COLORS.border}>
                <Text color={COLORS.text} fontWeight="semibold">Type & Data</Text>
              </Th>
              <Th borderRight="1px solid" borderColor={COLORS.border}>
                <Text color={COLORS.text} fontWeight="semibold">Actions</Text>
              </Th>
              {/* <Th width="88px">
                <Text color={COLORS.text} fontWeight="semibold">Manage</Text>
              </Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {tasks.map((task) => (
              <SortableRow
                key={task.id}
                task={task}
                onEdit={() => handleEdit(task)}
                onDelete={() => handleDelete(task)}
              />
            ))}
            {tasks.length === 0 && (
              <Tr>
                <Td colSpan={5}>
                  <Text textAlign="center" color="gray.500" py={4}>
                    No tasks added yet
                  </Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      <TaskModal
        isOpen={isEditOpen}
        onClose={onEditClose}
        onSubmit={handleTaskUpdate}
        initialData={selectedTask || undefined}
        isEditing={true}
      />

      <DeleteConfirmationDialog
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onConfirm={handleDeleteConfirm}
        title="Delete Task"
        itemName={selectedTask?.name}
      />
    </>
  );
};
